// import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
function NavbarComp() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-texture-1 navbar-dark sticky-top">
        <div className="container-fluid">
          <div className="navbar-img">
            <NavLink to="/">
              <img className="img-fluid" src="./images/JW-Circle.png" alt="" />
            </NavLink>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse m-2" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/AboutMe">
                  About Me
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Resume">
                  Resume
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Projects">
                  Projects
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavbarComp;
