import { useState, useEffect } from "react";
import axios from "axios";

function WelcomeForm() {
  const [modalTriggered, setModalTriggered] = useState(false);
  const [visitorType, setVisitorType] = useState("Guest");
  const [visitorName, setVisitorName] = useState("");
  const [visitorEmail, setVisitorEmail] = useState("");

  const [inputValidation, setInputValidation] = useState(null);

  const COOKIE_NAME = "WelcomeForm";
  const COOKIE_EXPIRE_MIN = 15;

  useEffect(() => {
    checkCookie(COOKIE_NAME);
    console.log("cookie");
  }, []);

  function checkCookie(cookieName) {
    let cookie = getCookie(cookieName);
    if (cookie === "") {
      setModalTriggered(true);
    }
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const addFormCookie = () => {
    const d = new Date();
    d.setMinutes(d.getMinutes() + COOKIE_EXPIRE_MIN);
    let expires = `expires=${d.toUTCString()}`;
    document.cookie = `${COOKIE_NAME}=${true};${expires};path=/`;
  };

  const hiddenCancelCookie = () => {
    const d = new Date();
    d.setDate(d.getDate() + 400);
    let expires = `expires=${d.toUTCString()}`;
    document.cookie = `${COOKIE_NAME}=${true};${expires};path=/`;
  };

  const onSubmit = async () => {
    const dateTime = new Date();
    const date = `${dateTime.getMonth()}/${dateTime.getDate()}/${dateTime.getFullYear()}`;
    const time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;
    if (visitorName.length === 0) {
      setInputValidation(false);
    } else {
      setInputValidation(true);
      setModalTriggered(false);
      let ip;
      await axios
        .get("https://geolocation-db.com/jsonp/")
        .then((res, req) => {
          ip = res.data;
        })
        .catch(() => {
          ip = "Failed To get Location";
        });

      axios({
        method: "post",
        url: "https://backend.jakeweinrich.com/WelcomeForm",
        data: {
          visitorName: visitorName,
          visitorType: visitorType,
          visitorEmail: visitorEmail,
          Date: date,
          Time: time,
          Ip: ip,
        },
      })
        .then((res, req) => {
          console.log(`Form Submit Response: ${res.data}`);
        })
        .catch((res, req) => {
          console.log("Error Sending Form");
        });
      addFormCookie();
    }
  };

  const onCancel = async () => {
    setModalTriggered(false);
    let ip;
    const dateTime = new Date();
    const date = `${dateTime.getMonth()}/${dateTime.getDate()}/${dateTime.getFullYear()}`;
    const time = `${dateTime.getHours()}:${dateTime.getMinutes()}`;
    await axios
      .get("https://geolocation-db.com/jsonp/")
      .then((res, req) => {
        ip = res.data;
      })
      .catch(() => {
        ip = "Failed To get Location";
      });
    // console.log(ip)
    axios({
      method: "post",
      url: "https://backend.jakeweinrich.com/WelcomeForm/cancel",
      data: {
        CanceledForm: `${ip}`,
        Date: date,
        Time: time,
      },
    })
      .then((res, req) => {
        console.log(`Form Submit Response: ${res.data}`);
      })
      .catch((res, req) => {
        console.log("Error Sending Form");
      });
    addFormCookie();
  };

  return (
    <div
      className={`modal `}
      style={{ display: modalTriggered ? "block" : "none" }}
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content bg-dark text-white">
          <div className="modal-header">
            <h1 className="modal-title fs-5 text-lime" id="staticBackdropLabel">
              Welcome to Jacob Weinrich's Portfolio
            </h1>
            <button
              type="button"
              className="btn p-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setModalTriggered(false);
                hiddenCancelCookie();
              }}
            />
          </div>
          <form>
            <div className="modal-body text-white">
              <span className="text-white-2">Please take a moment to fill out this quick survey.</span>
              <h5>Who's Visiting?</h5>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="VisitorType"
                    id="VisitorType1"
                    value={"Guest"}
                    defaultChecked
                    onClick={(e) => {
                      setVisitorType("Guest");
                    }}
                  />
                  <label className="form-check-label" htmlFor="VisitorType1">
                    Guest
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="VisitorType"
                    id="VisitorType2"
                    value={"Employer"}
                    onClick={(e) => {
                      setVisitorType("Employer");
                    }}
                  />
                  <label className="form-check-label" htmlFor="VisitorType2">
                    Employer
                  </label>
                </div>
              </div>
              {/* end */}
              <h5 className="mb-0">Name/Company Name</h5>
              {inputValidation === false ? (
                <span className="text-danger">Name/Company Name is Required!</span>
              ) : (
                ""
              )}
              <input
                type="text"
                id="VisitorName"
                className="form-control"
                placeholder="Name/Company Name"
                value={visitorName}
                onChange={(e) => {
                  setVisitorName(e.currentTarget.value);
                }}
              />
              <h5 className="mb-0 mt-1">Email (Optional)</h5>
              <input
                type="text"
                id="VisitorName"
                className="form-control"
                placeholder="Example@EmailEx.com"
                value={visitorEmail}
                onChange={(e) => {
                  setVisitorEmail(e.currentTarget.value);
                }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setModalTriggered(false);
                  onCancel();
                }}
              >
                Cancel
              </button>
              {/* <button
                type="button"
                onClick={(e) => console.log({ visitorType: visitorType, visitorName: visitorName })}
              >
                test
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default WelcomeForm;
