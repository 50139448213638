/* ~Module Imports~ */
import { Routes, Route } from "react-router-dom";
/* ~Css Style Imports~ */
import "./App.css";
import "./comps/css/compCss.css";
/* ~Pages~ */
/* ~Components~ */
import NavbarComp from "./comps/NavbarComp";
import MainPageComp from "./comps/pages/MainPageComp";
import AboutMePageComp from "./comps/pages/AboutMePageComp";
import ResumePageComp from "./comps/pages/ResumePageComp";
import MyWorkPageComp from "./comps/pages/MyWorkPageComp";
import Footer from "./comps/Footer";
import WelcomeForm from "./comps/WelcomeForm";
import SportsProComp from "./comps/pages/projects/SportsProComp";
import IssueTrackerComp from "./comps/pages/projects/IssueTrackerComp";
import LaundroDropComp from "./comps/pages/projects/LaundroDropComp";
import CrudAppComp from "./comps/pages/projects/CrudAppComp";

function App() {
  return (
    <>
      <WelcomeForm/>
      <NavbarComp />
      <div className="static-bg p-lg-2 p-1">
        <Routes>
          <Route path="/" element={<MainPageComp />} />
          <Route path="/AboutMe" element={<AboutMePageComp />} />
          <Route path="/Resume" element={<ResumePageComp />} />
          <Route path="/Projects" element={<MyWorkPageComp />} />
          {/* Projects */}
          <Route path="/SportsPro" element={<SportsProComp />} />
          <Route path="/IssueTracker" element={<IssueTrackerComp />} />
          <Route path="/LaundroDrop" element={<LaundroDropComp />} />
          <Route path="/CrudApp" element={<CrudAppComp />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
