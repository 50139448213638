function CrudAppComp() {
  return (
    <>
      <div className="default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4 d-flex flex-column flex-nowrap">
        <div className="text-center text-white">
          <h5 className="display-4 fw-semibold">Project</h5>
          <h4 className="display-3 fw-normal">Crud React App</h4>
          <hr />
          <a
            className="link-lime font-1 fs-3"
            target="_blank"
            href="https://youtu.be/GAB2srqZvbY?feature=shared"
            rel="noreferrer"
          >
            Youtube Preview
          </a>

          <br />
          <hr />
          <div className="row justify-content-center">
            <h4 className="fs-1 fw-semibold">Description</h4>
            <div className="col-12 col-lg-6">
              <p className="">
                "React CRUD App" marks my inaugural venture into ReactJS during my first semester at college.
                This introductory project showcases my foundational skills in creating a dynamic web
                application with essential CRUD (Create, Read, Update, Delete) functionalities. With features
                like student search, addition, editing, and deletion, the application offers a comprehensive
                solution for managing student data efficiently. Through this project, I gained valuable
                experience in leveraging ReactJS to develop intuitive user interfaces and handling basic data
                operations, setting the stage for further exploration and growth in web development.
              </p>
            </div>
          </div>
          <div className="row justify-content-center gy-2">
            <div className="col-12 col-md-6">
              <h5>Main Page</h5>
              <img
                className="img-fluid"
                src="./images/projects/crudApp/MainScreen.png"
                alt="Login Page ScreenShot"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CrudAppComp;
