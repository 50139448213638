function LaundroDropComp() {
  return (
    <>
      <div className="default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4 d-flex flex-column flex-nowrap">
        <div className="text-center text-white">
          <h5 className="display-4 fw-semibold">Project</h5>
          <h4 className="display-3 fw-normal">Laundro Drop</h4>
          <hr />
          <a
            className="link-lime font-1 fs-3"
            target="_blank"
            href="https://youtu.be/V0723hNUOkA?feature=shared"
            rel="noreferrer"
          >
            Youtube Preview
          </a>
          <br />
          <a
            className="link-lime font-1 fs-3"
            target="_blank"
            href="https://github.com/JacobWeinrich/LaundroDrop-Orders/tree/aeae2e6a097db335540b1d5a29530c2832fdfb4e/Build%20Versions%20-%20With%20Souce%20Code%20Backups/V1.0.0%20-%205-3-2023/LaundryOrderApp%20-%20Source%20Code"
            rel="noreferrer"
          >
            Github Repo
          </a>
          <br />
          <hr />
          <div className="row justify-content-center">
            <h4 className="fs-1 fw-semibold">Description</h4>
            <div className="col-12 col-lg-6">
              <p className="">
                "Laundro Drop" is my latest project, developed with C# and SQL, catering specifically to the
                needs of laundromat employees. Designed for seamless order tracking in a drop-off and pickup
                service environment, this application empowers employees with the tools they need to
                efficiently manage laundry orders.
              </p>
              <p>
                At its core, "Laundro Drop" streamlines the workflow for employees by providing a
                user-friendly interface for tracking orders throughout the service cycle. From receiving
                drop-off items to processing orders for washing, drying, and folding, employees can easily
                manage each step of the process with precision and efficiency.
              </p>
              <p>
                Key features of "Laundro Drop" include comprehensive order tracking capabilities, allowing
                employees to monitor the status of each order in real-time. With integrated SQL databases,
                order information is securely stored and readily accessible, ensuring smooth operations and
                minimizing errors.
              </p>
              <p>
                Moreover, "Laundro Drop" empowers employees with the ability to generate and print receipts
                for customers, providing a tangible record of transactions. This feature enhances customer
                service and satisfaction while also facilitating accurate billing and order management.
              </p>
              <p>
                Designed specifically with the needs of laundromat employees in mind, "Laundro Drop" offers a
                tailored solution that enhances productivity and efficiency in the workplace. Its intuitive
                interface and powerful features make it an indispensable tool for employees tasked with
                managing laundry orders and providing exceptional service to customers.
              </p>
            </div>
          </div>
          <div className="row justify-content-center gy-2">
            <div className="col-12 col-md-6">
              <h5>Main Page</h5>
              <img
                className="img-fluid"
                src="./images/projects/laundroDrop/MainPage.png"
                alt="Login Page ScreenShot"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaundroDropComp;
