import { useEffect } from "react";
function ResumePageComp() {
  useEffect(() => {
    document.title = "Resume - Jacob's Portfolio";
  }, []);
  return (
    <>
      <div className="page-resume default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4">
        <h1 className="display-1 text-white-2 font-1 text-center">Resume</h1>
        <hr className="hr-1" />
        <div className="text-center">
          <a
            className="link-lime fs-4"
            target="_blank"
            href="./images/pdfs/Resume.pdf"
            download={true}
          >
            Download - (Version: 2024)
          </a>
          <hr className="hr-1" />
        </div>
        <div className="text-center row justify-content-center mt-4">
          <iframe
            className="resume-iframe"
            src="./images/pdfs/Resume.pdf"
            title="Resume"
          ></iframe>
          <img
            className="resume-img img-fluid mt-1"
            src="./images/pdfs/Resume.png"
            alt="Resume"
          />
          <br />
        </div>
      </div>
    </>
  );
}

export default ResumePageComp;
