import { useEffect } from "react";
function AboutMePageComp() {
  useEffect(() => {
    document.title = "AboutMe - Jacob's Portfolio";
  }, []);
  return (
    <>
      <div className="page-aboutme default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4">
        {/* Header */}
        <div className="aboutMe-sec-header">
          <h1 className="display-3 text-center text-white-2 font-1">About Me</h1>
          <div className="row justify-content-center">
            <div className="col-6 col-lg-2">
              <img className="img-fluid" src="./images/Profile-Circle.png" alt="" />
            </div>
            <h2 className="fs-1 mt-2 text-center text-white-2 font-1">Jacob Weinrich</h2>
          </div>
        </div>
        <hr className="hr-1" />
        <div className="row justify-content-center">
          <p className="text-white fs-5 text-center col-12 col-sm-10 col-lg-6 mb-0">
            Hello I'm Jacob Weinrich, a recent college graduate with a degree in Web & App Development,
            Proficient in a range of programming languages, including C#, MVC.Net, HTML, CSS, JS, ReactJS, and
            NodeJs, among others. I am enthusiastic about using my skills to contribute effectively to
            innovative projects and further develop my expertise in the ever-evolving tech landscape. In my
            free time I enjoy playing video games, 3d Printing, and wood working.
          </p>
        </div>
        <hr className="hr-1" />

        <div className="row justify-content-center aboutMe-sec-info text-start text-white-2 fs-5 ">
          <div className="col-12 col-lg-4">
            <span className="fw-bold text-lime fs-4">College Degree:</span>
            <div className="text-indent-1">
              <span>
                <strong>Associates of Technology</strong>: <br className="hide-500" /> Application & Web
                Development
              </span>
              <div className="text-indent-1">
                <span>
                  <strong>Graduated</strong>: May 3 2024
                </span>
              </div>
              <div className="text-indent-1">
                <span>
                  <strong>GPA</strong>: 4.0 | <strong>Attendance</strong>: 100%
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <span className="fw-bold text-lime fs-4">College:</span>
            <div className="text-indent-1">
              <a className="link-light" target="_blank" rel="noreferrer" href="https://ranken.edu/">
                Ranken Technical College
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMePageComp;
