function Footer() {
  return (
    <footer className="bg-black-1 text-white p-1">
      <div className="container-fluid">
        <div className="ms-2">&copy;{new Date().getFullYear()} Jacob Weinrich - Portfolio</div>
      </div>
    </footer>
  );
}

export default Footer;
