function IssueTrackerComp() {
  return (
    <>
      <div className="default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4 d-flex flex-column flex-nowrap">
        <div className="text-center text-white">
          <h5 className="display-4 fw-semibold">Project</h5>
          <h4 className="display-3 fw-normal">Issue Tracker</h4>
          <hr />
          <a
            className="link-lime font-1 fs-3"
            target="_blank"
            href="https://jw-issue-tracker-ldfncqkyrq-uc.a.run.app//"
            rel="noreferrer"
          >
            View Live Site
          </a>
          <br />
          <span className="fs-5">A test account should be in the login text boxes already.</span>
          <hr />
          <div className="row justify-content-center">
            <h4 className="fs-1 fw-semibold">Description</h4>
            <div className="col-12 col-lg-6">
              <p className="">
                "Issue Tracker" is a class project, showcasing my proficiency in leveraging MongoDB, Express,
                ReactJS, and NodeJS (MERN stack) to develop a sophisticated platform for streamlined issue
                management. This project serves as a comprehensive demonstration of my ability to utilize
                modern web development technologies to create robust solutions tailored to the needs of
                organizations and teams.
              </p>
              <p>
                At its core, Issue Tracker embodies the essence of efficiency and effectiveness in issue
                management. With its intuitive user interface built using ReactJS, users are seamlessly guided
                through the process of reporting new issues and updating existing ones. The dynamic nature of
                the application, powered by NodeJS and Express, ensures smooth communication between the
                frontend and backend, facilitating real-time updates and responses.
              </p>
              <p>
                One of the standout features of Issue Tracker is its robust user authentication system, which
                not only ensures the security of the platform but also enables granular control over access
                permissions. Through user login and authorization mechanisms, specific website functions are
                restricted or granted based on predefined roles, enhancing security and usability.
              </p>
              <p>
                Furthermore, the ability to assign technicians or users to open issue reports adds a layer of
                organization and accountability to the process. By streamlining the assignment process, teams
                can ensure that issues are promptly addressed by the appropriate personnel, reducing response
                times and improving overall efficiency
              </p>
              <p>
                In essence, Issue Tracker demonstrates my ability to leverage the MERN stack to develop
                powerful, user-friendly applications that meet the complex demands of modern issue management.
                Its seamless integration of frontend and backend technologies, coupled with robust security
                features and intuitive functionalities, showcases my dedication to delivering high-quality
                solutions. Whether it's tracking and resolving technical issues, managing tasks, or enhancing
                collaboration, Issue Tracker reflects my commitment to excellence in web development.
              </p>
            </div>
          </div>
          <div className="row justify-content-center gy-2">
            <div className="col-12 col-md-6">
              <h5>Live Chat Popup</h5>
              <img
                className="img-fluid"
                src="./images/projects/issueTracker/LiveChat.png"
                alt="Login Page ScreenShot"
              />
            </div>
            <div className="col-12 col-md-6">
              <h5>Login Page</h5>
              <img
                className="img-fluid"
                src="./images/projects/issueTracker/Login.png"
                alt="Login Page ScreenShot"
              />
            </div>
            <div className="col-12 col-md-6">
              <h5>Users List</h5>
              <img
                className="img-fluid"
                src="./images/projects/issueTracker/UsersList.png"
                alt="Login Page ScreenShot"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IssueTrackerComp;
