import { useEffect } from "react";
import { NavLink } from "react-router-dom";

function MyWorkPageComp() {
  useEffect(() => {
    document.title = "Projects - Jacob's Portfolio";
  }, []);
  return (
    <>
      <div className="myWork-page default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4 d-flex flex-column flex-nowrap">
        <h1 className="display-2 text-white-2 font-1 text-center">Projects</h1>
        <hr className="hr-1 order-1 order-md-0" />
        <div className="text-white row justify-content-center order-2 order-md-0">
          <h2 className="text-center fs-1 font-1">My Portfolio Site</h2>
          <p className="text-white fs-5 text-center col-12 col-sm-10 col-lg-6 mb-0">
            My portfolio site is built on a ReactJs frontend using a combination of Bootstrap, FontAwesome,
            Google/Adobe Fonts, and custom CSS for colors and background images. On the backend I have a
            NodeJs app running with ExpressJs for the Welcome Form that is displayed when the website is first
            opened. On the form submission a http post request is sent to the the backend then NodeJs sends me
            a email using NodeMailer containing the form data, along with the http post the frontend stores a
            Cookie to keep the form from showing every time a visitor refreshes the page. The entire websites
            front and backend are hosted on A2 Hosting using C-Panel.
          </p>
        </div>
        <hr className="hr-1" />
        <div className="text-white row justify-content-center">
          <h2 className="text-center fs-2 font-1">Learning New Skills</h2>
          <span className="text-white fs-5 text-center col-12 col-sm-10 col-lg-6 mb-0">
            <li>
              Actively pursuing proficiency in Amazon Web Services (AWS) and expanding knowledge of Microsoft
              Azure, aligning with industry trends and demands.
            </li>
            <li>
              Aspires to acquire expertise in Microsoft MAUI and Blazor technologies.
            </li>
          </span>
        </div>
        <hr className="hr-1" />
        <div className="text-white">
          <h2 className="text-center fs-1 font-1">Company Websites</h2>
          <h3 className="text-center fs-5 text-white-2 font-1">Note: More company sites coming soon.</h3>
          <div className="row justify-content-center fs-5">
            <div className="col-12 col-md-6">
              <ul>
                {/* Weinrich Construction */}
                <li>
                  <a
                    className="link-lime font-1"
                    target="_blank"
                    href="https://weinrichconstructionstl.com/"
                    rel="noreferrer"
                  >
                    Weinrich Construction
                  </a>
                  <ul>
                    <li>Created with HTML CSS & JS.</li>
                  </ul>
                </li>
                {/* Ben Stevens Crane */}
                <li>
                  <a
                    className="link-lime font-1"
                    target="_blank"
                    href="https://stevenscrane.a2hosted.com/"
                    rel="noreferrer"
                  >
                    Stevens Crane L.L.C.
                  </a>
                  <ul>
                    <li>Created with React JS.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="hr-1" />
        {/* C# */}
        <div className="text-white">
          <h2 className="text-center fs-1 font-1">Class & Personal Projects</h2>
          <h3 className="text-center fs-5 text-white-2 font-1">
            Note: Other class projects/lab work & personal projects available upon request.
          </h3>
          <div className="row justify-content-center fs-5">
            <div className="col-12 col-md-6">
              <ul>
                <li>
                  <a
                    className="link-lime font-1"
                    target="_blank"
                    href="https://sportpro-finalproject.azurewebsites.net/"
                    rel="noreferrer"
                  >
                    Sports Pro (ASP.Net Core MVC)
                  </a>
                  <br />
                  <span className="text-white fw-semibold">
                    Test Account: (<strong>Username</strong>: Admin | <strong>Password</strong>: P@ssw0rd)
                  </span>
                  <br />
                  <span className="text-white">
                    Sports Pro: Streamlined sports management with ASP.Net Core MVC and SQL on Azure.
                  </span>
                  <ul>
                    <li>
                      <NavLink className="link-lime font-1" to="/SportsPro">
                        Learn More
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* Issue Tracker */}
                <li>
                  <a
                    className="link-lime font-1"
                    target="_blank"
                    href="https://jw-issue-tracker-ldfncqkyrq-uc.a.run.app/"
                    rel="noreferrer"
                  >
                    Issue Tracker (MongoDB/MERN Stack)
                  </a>
                  <br />
                  <span className="text-white fw-semibold">
                    A test account should be in the login text boxes already.
                  </span>
                  <br />
                  <span className="text-white">
                    Issue Tracker: Efficient MERN stack solution for streamlined issue management.
                  </span>
                  <ul>
                    <li>
                      <NavLink className="link-lime font-1" to="/IssueTracker">
                        Learn More
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* Laundro Drop Youtube */}
                <li>
                  <span className="text-lime font-1">Laundro Drop/Order Database</span>
                  <br />
                  <span className="text-white">
                    Laundro Drop: Empowering laundromat employees with seamless order management
                  </span>
                  <br />
                  <ul>
                    <li>
                      <NavLink className="link-lime font-1" to="/LaundroDrop">
                        Learn More
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* Crud React App */}
                <li>
                  <span className="text-lime font-1">ReactJS CRUD App</span>
                  <br />
                  <span className="text-white">
                    Crud React App: My first semester intro to ReactJs CRUD App
                  </span>
                  <ul>
                    <li>
                      <NavLink className="link-lime font-1" to="/CrudApp">
                        Learn More
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* Law Day Run Midterm */}
                <li>
                  <span className="text-lime font-1">Law Day Run Midterm - Upon Request</span>
                  <ul>
                    <li>Created using HTML CSS & JS.</li>
                    <li>
                      Website for a charity marathon group that we rebuilt/updated as a midterm project at
                      Ranken Technical College.
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="text-lime font-1">Simple HTML & JS Games - Upon Request</span>
                  <ul>
                    <li>Created Using HTML CSS & JS.</li>
                    <li>
                      Simple HTML & JS Games is a side project I built in my free time when I finished my
                      class work early.
                    </li>
                    <li>
                      <span className="text-lime">Games</span>
                      <ul>
                        <li>HangMan</li>
                        <li>Tic Tac Toe</li>
                        <li>Matching Game</li>
                        <li>Basic Slot Machine Game</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyWorkPageComp;
