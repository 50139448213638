import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import {
  faLinkedin,
  faNodeJs,
  faReact,
  faHtml5,
  faCss3,
  faJs,
  faNpm,
  faBootstrap,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import "../custom-icons/logoMongoDB.css";
import "../custom-icons/logoVSCode.css";
import "../custom-icons/logoGitHub.css";
import "../custom-icons/logoPostman.css";
import "../custom-icons/logoMicroOffice.css";
import "../custom-icons/logoUML.css";
import "../custom-icons/logoSQL.css";
import "../custom-icons/logoCSharp.css";
import "../custom-icons/logoPhotoShop.css";

function MainPageComp() {
  useEffect(() => {
    document.title = "Home - Jacob's Portfolio";
  }, []);

  return (
    <>
      <div className="page-main default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4">
        {/* Section Header */}
        <div className="main-sec-header">
          <div className="row justify-content-center">
            <div className="col-6 col-lg-3">
              <img className="img-fluid" src="./images/JW-Circle.png" alt="" />
            </div>
          </div>
          <div className="row justify-content-center p-2">
            <h1 className="fs-2 text-center text-white-2 font-1">
              Jacob Weinrich's <br /> Portfolio
            </h1>
            <div className="row justify-content-center mb-2">
              <NavLink
                className="btn btn-cGreen me-sm-2 mb-sm-0 col-8 col-sm-4 col-md-3 col-lg-2 fw-semibold text-white fs-4 p-0"
                to="/Projects"
              >
                My Projects
              </NavLink>
            </div>
            <div className="row justify-content-center">
              <NavLink
                className="btn btn-cGreen me-sm-2 mb-sm-0 mb-2  col-8 col-sm-4 col-md-3 col-lg-2 fw-semibold text-white fs-4 p-0"
                to="/Resume"
              >
                My Resume
              </NavLink>
              <NavLink
                className="btn btn-cGreen col-8 col-sm-4 col-md-3 col-lg-2 fw-semibold text-white fs-4 p-0"
                to="/AboutMe"
              >
                About Me
              </NavLink>
            </div>
          </div>
        </div>
        <hr className="hr-1" />
        {/* Section Contacts */}
        <div className="main-sec-contact">
          <div className="row justify-content-center text-center gy-2">
            <div className="col-lg-4 col-12">
              <a className="text-decoration-none link-lime fs-4" href="tel:+13149399335">
                <i className="bi bi-telephone"></i>&nbsp; (314)-939-9335
              </a>
            </div>
            <div className="col-lg-4 col-12">
              <a className="text-decoration-none link-lime fs-4" href="mailto:jacobw@segotw.com">
                <i className="bi bi-envelope"></i>&nbsp; JacobW@segotw.com
              </a>
            </div>
            <div className="col-12 row justify-content-center text-center gy-2 gy-lg-1">
              <div className="col-lg-4 col-12">
                <a
                  className="text-decoration-none link-lime fs-4"
                  target="_blank"
                  href="https://www.linkedin.com/in/jacob-weinrich/"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedin} className="fs-3" />
                  &nbsp; LinkedIn
                </a>
              </div>
              <div className="col-lg-4 col-12">
                <a
                  className="text-decoration-none link-lime fs-4"
                  target="_blank"
                  href="https://github.com/JacobWeinrich"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faGithub} className="fs-3" />
                  &nbsp; GitHub
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <a
                className="text-decoration-none link-lime fs-4"
                href="geo:38.43042272749599,-90.51539591033793"
              >
                <div className="main-sec-contact-geo justify-content-center">
                  <div className="text-end align-self-center">
                    <i className="bi bi-geo-alt"></i>&nbsp;
                  </div>
                  5240 Windswept Ln,
                  <br />
                  House Springs, MO 63051
                </div>
              </a>
            </div>
          </div>
        </div>
        <hr className="hr-1" />
        <div className="text-center text-white">
          <h2 className="fs-1 text-center m-4 mb-1 text-white-2 font-1">Featured Projects</h2>
          <NavLink
            className="btn btn-cGreen me-sm-2 mb-sm-0 mb-2  col-8 col-sm-4 col-md-3 col-lg-2 fw-semibold text-white fs-4 p-0"
            to="/Projects"
          >
            More Projects
          </NavLink>
          <ul className="list-unstyled mt-2">
            <li>
              <a
                className="link-lime font-1 fs-5"
                target="_blank"
                href="https://sportpro-finalproject.azurewebsites.net/"
                rel="noreferrer"
              >
                Sports Pro (ASP.Net Core MVC)
              </a>
              <br />
              <span className="text-white fw-semibold">
                Test Account: (<strong>Username</strong>: Admin | <strong>Password</strong>: P@ssw0rd)
              </span>
              <br />
              <span className="text-white">
                Sports Pro: Streamlined Sports Management with ASP.Net Core MVC and SQL on Azure.
              </span>
              <ul className="list-unstyled">
                <li>
                  <NavLink className="link-lime font-1" to="/SportsPro">
                    Learn More
                  </NavLink>
                </li>
              </ul>
            </li>
            <br />
            {/* Issue Tracker */}
            <li>
              <a
                className="link-lime font-1 fs-5"
                target="_blank"
                href="https://jw-issue-tracker-ldfncqkyrq-uc.a.run.app/"
                rel="noreferrer"
              >
                Issue Tracker (MongoDB/MERN Stack)
              </a>
              <br />
              <span className="text-white fw-semibold">
                A test account should be in the login text boxes already.
              </span>
              <br />
              <span className="text-white">
                Issue Tracker: Efficient MERN Stack Solution for Streamlined Issue Management.
              </span>
              <ul className="list-unstyled">
                <li>
                  <NavLink className="link-lime font-1" to="/IssueTracker">
                    Learn More
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <hr className="hr-1" />
        {/* Section Skills */}
        <div className="main-sec-skills">
          <h2 className="fs-1 text-center m-4 text-white-2 font-1">Programming Languages</h2>
          <div className="row display-4 text-lime text-center justify-content-center gy-3">
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faHtml5} />
              <span className="fs-4 font-1 text-white-2 mt-1">HTML</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faCss3} />
              <span className="fs-4 font-1 text-white-2 mt-1">CSS</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faJs} />
              <span className="fs-4 font-1 text-white-2 mt-1">JavaScript</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faBootstrap} />
              <span className="fs-4 font-1 text-white-2 mt-1">Bootstrap</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faNpm} />
              <span className="fs-4 font-1 text-white-2 mt-1">npm</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faNodeJs} />
              <span className="fs-4 font-1 text-white-2 mt-1">Node JS</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faReact} />
              <span className="fs-4 font-1 text-white-2 mt-1">React JS</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <FontAwesomeIcon icon={faJs} />
              <span className="fs-4 font-1 text-white-2 mt-1">Express JS</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-MongoDB i-logo-MongoDB"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">MongoDB</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-CSharp i-logo-CSharp"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">C#</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-CSharp i-logo-CSharp"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">ASP.Net</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-SQL i-logo-SQL"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">SQL</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-UML i-logo-UML"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">
                UML
                <br />
                Diagrams
              </span>
            </div>
          </div>
        </div>
        <hr className="hr-1" />
        {/* Section Programs */}
        <div className="main-sec-programs">
          <h2 className="fs-1 text-center m-4 text-white-2 font-1">Applications / Software</h2>
          <div className="row display-4 text-lime text-center justify-content-center gy-3">
            <div className="col-6 col-lg-3 main-sec-programs-items">
              <i className="logo-VSCode i-logo-VSCode"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">VS Code</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-programs-items">
              <i className="logo-VSCode i-logo-VSCode"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">
                Visual
                <br />
                Studio
              </span>
            </div>
            <div className="col-6 col-lg-3 main-sec-programs-items">
              <i className="i-logo-GitHub logo-GitHub"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">GitHub</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-programs-items">
              <i className="i-logo-GitHub logo-GitHub"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">
                GitHub
                <br />
                Desktop
              </span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-MongoDB i-logo-MongoDB"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">
                MongoDB
                <br />
                Shell
              </span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-MongoDB i-logo-MongoDB"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">
                MongoDB
                <br />
                Compass
              </span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-Postman i-logo-Postman"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">Postman</span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-MicroOffice i-logo-MicroOffice"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">
                Microsoft <br /> Office
              </span>
            </div>
            <div className="col-6 col-lg-3 main-sec-skills-items">
              <i className="logo-PhotoShop i-logo-PhotoShop"></i>
              <span className="fs-4 font-1 text-white-2 mt-1">Photo Shop</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainPageComp;
