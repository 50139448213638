function SportsProComp() {
  return (
    <>
      <div className="default-page bg-default-1 rounded-3 rounded-lg-5 container-fluid p-4 d-flex flex-column flex-nowrap">
        <div className="text-center text-white">
          <h5 className="display-4 fw-semibold">Project</h5>
          <h4 className="display-3 fw-normal">Sports Pro</h4>
          <hr />
          <a
            className="link-lime font-1 fs-3"
            target="_blank"
            href="https://sportpro-finalproject.azurewebsites.net/"
            rel="noreferrer"
          >
            View Live Site
          </a>
          <br />
          <span className="fs-5">
            Test Account: (<strong>Username</strong>: Admin | <strong>Password</strong>: P@ssw0rd)
          </span>
          <hr />
          <div className="row justify-content-center">
            <h4 className="fs-1 fw-semibold">Description</h4>
            <div className="col-12 col-lg-6">
              <p className="">
                "Sports Pro" is a dynamic ASP.Net Core MVC project showcasing the seamless integration of
                cutting-edge technology to streamline sports management processes. Leveraging the power of SQL
                databases, it efficiently stores and manages diverse data sets, including customer
                information, product details, and incident reports.
              </p>
              <p>
                With an intuitive user interface and robust backend architecture, Sports Pro offers a
                comprehensive solution for sports organizations to manage their operations effectively.
                Whether it's tracking customer interactions, managing product inventory, or handling incident
                reports, this project provides a centralized platform for streamlined workflows.
              </p>
              <p>
                Hosted on Azure, Sports Pro demonstrates scalability, reliability, and accessibility, ensuring
                optimal performance and accessibility for users. This project exemplifies the fusion of modern
                web development techniques with cloud infrastructure, delivering a seamless and efficient
                solution for sports management needs.
              </p>
            </div>
          </div>
          <div className="row justify-content-center gy-2">
            <div className="col-12 col-md-6">
              <h5>Login Page</h5>
              <img
                className="img-fluid"
                src="./images/projects/sports-pro/Login.png"
                alt="Login Page ScreenShot"
              />
            </div>
            <div className="col-12 col-md-6">
              <h5>Register User Page</h5>
              <img
                className="img-fluid"
                src="./images/projects/sports-pro/Register.png"
                alt="Login Page ScreenShot"
              />
            </div>
            <div className="col-12 col-md-6">
              <h5>Product List</h5>
              <img
                className="img-fluid"
                src="./images/projects/sports-pro/ListExample.png"
                alt="Login Page ScreenShot"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SportsProComp;
